import { useEffect } from "react";

const CookieConsentScript = "https://cdn.jsdelivr.net/gh/orestbida/cookieconsent@v2.8.6/dist/cookieconsent.js";
const CookieConsentStyle = "https://cdn.jsdelivr.net/gh/orestbida/cookieconsent@v2.8.6/dist/cookieconsent.css";

export default function CookieConsent() {
    useEffect(() => {
        const callCC = () => {
            let cc;
            try {
                cc = window.initCookieConsent();
            } catch (error) {
                console.error(error);
            }
            if (cc) {
                cc.run({
                    autorun: true,
                    current_lang: typeof window !== "undefined" ? document.documentElement.getAttribute("lang") : "en",
                    autoclear_cookies: true,
                    page_scripts: true,

                    onFirstAction: function (user_preferences, cookie) {},

                    onAccept: function (cookie) {},

                    onChange: function (cookie, changed_preferences) {},

                    languages: {
                        en: {
                            consent_modal: {
                                title: "I use cookies",
                                description:
                                    'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only upon approval. <!-- <a aria-label="Cookie policy" class="cc-link" href="#">Read more</a> -->',
                                primary_btn: {
                                    text: "Accept",
                                    role: "accept_all", // 'accept_selected' or 'accept_all'
                                },
                                secondary_btn: {
                                    text: "Settings",
                                    role: "settings", // 'settings' or 'accept_necessary'
                                },
                            },
                            settings_modal: {
                                title: "Cookie preferences",
                                save_settings_btn: "Save settings",
                                accept_all_btn: "Accept all",
                                reject_all_btn: "Reject all", // optional, [v.2.5.0 +]
                                cookie_table_headers: [{ col1: "Name" }, { col2: "Domain" }, { col3: "Expiration" }, { col4: "Description" }, { col5: "Type" }],
                                blocks: [
                                    {
                                        title: "Cookie usage",
                                        description:
                                            "I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want.",
                                    },
                                    {
                                        title: "Strictly necessary cookies",
                                        description:
                                            "These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly.",
                                        toggle: {
                                            value: "necessary",
                                            enabled: true,
                                            readonly: true,
                                        },
                                    },
                                    {
                                        title: "Analytics cookies",
                                        description:
                                            "These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you.",
                                        toggle: {
                                            value: "analytics",
                                            enabled: false,
                                            readonly: false,
                                        },
                                        cookie_table: [
                                            {
                                                col1: "^_ga",
                                                col2: "google.com",
                                                col3: "2 years",
                                                col4: "description ...",
                                                col5: "Permanent cookie",
                                                is_regex: true,
                                            },
                                            {
                                                col1: "_gid",
                                                col2: "google.com",
                                                col3: "1 day",
                                                col4: "description ...",
                                                col5: "Permanent cookie",
                                            },
                                        ],
                                    },
                                    {
                                        title: "More information",
                                        description:
                                            'For any queries in relation to my policy on cookies and your choices, please <a class="cc-link" href="mailto:lukas@code8.cz">contact me</a>.',
                                    },
                                ],
                            },
                        },
                        cs: {
                            consent_modal: {
                                title: "Používáme cookies",
                                description:
                                    'Dobrý den, tento web používá nezbytné soubory cookie k zajištění správného fungování a sledovací soubory cookie.<!-- <a aria-label="Cookie policy" class="cc-link" href="#">Přečtěte si více</a> -->',
                                primary_btn: {
                                    text: "Přijmout",
                                    role: "accept_all", // 'accept_selected' or 'accept_all'
                                },
                                secondary_btn: {
                                    text: "Nastavení",
                                    role: "settings", // 'settings' or 'accept_necessary'
                                },
                            },
                            settings_modal: {
                                title: "Předvolby souborů cookie",
                                save_settings_btn: "Uložit nastavení",
                                accept_all_btn: "Přijmout vše",
                                reject_all_btn: "Odmítnout vše", // optional, [v.2.5.0 +]
                                cookie_table_headers: [{ col1: "Name" }, { col2: "Domain" }, { col3: "Expiration" }, { col4: "Description" }, { col5: "Type" }],
                                blocks: [
                                    {
                                        title: "Používání souborů cookie",
                                        description:
                                            "Soubory cookie používám k zajištění základních funkcí webu a ke zlepšení vašeho online zážitku. Pro každou kategorii si můžete vybrat, zda se chcete přihlásit/odhlásit, kdykoli budete chtít.",
                                    },
                                    {
                                        title: "Nezbytně nutné cookies",
                                        description: "Tyto soubory cookie jsou nezbytné pro správné fungování mých webových stránek. Bez těchto cookies by web nefungoval správně.",
                                        toggle: {
                                            value: "necessary",
                                            enabled: true,
                                            readonly: true,
                                        },
                                    },
                                    {
                                        title: "Analytické soubory cookie",
                                        description:
                                            "Tyto soubory cookie shromažďují informace o tom, jak web používáte, které stránky jste navštívili a na které odkazy jste klikli. Všechna data jsou anonymizována a nelze je použít k vaší identifikaci.",
                                        toggle: {
                                            value: "analytics",
                                            enabled: false,
                                            readonly: false,
                                        },
                                        cookie_table: [
                                            {
                                                col1: "^_ga",
                                                col2: "google.com",
                                                col3: "2 years",
                                                col4: "description ...",
                                                col5: "Permanent cookie",
                                                is_regex: true,
                                            },
                                            {
                                                col1: "_gid",
                                                col2: "google.com",
                                                col3: "1 day",
                                                col4: "description ...",
                                                col5: "Permanent cookie",
                                            },
                                        ],
                                    },
                                    {
                                        title: "Více informací",
                                        description:
                                            'Máte-li jakékoli dotazy týkající se mých zásad týkajících se souborů cookie a vašich voleb, prosím <a class="cc-link" href="mailto:lukas@code8.cz">kontaktujte mě</a>.',
                                    },
                                ],
                            },
                        },
                    },
                });
            }
        };

        const script = document.createElement("script");
        script.src = CookieConsentScript;
        script.type = "application/javascript";
        document.head.appendChild(script);
        const style = document.createElement("link");
        style.href = CookieConsentStyle;
        style.rel = "stylesheet";
        style.media = "all";
        document.head.appendChild(style);

        const timer = setTimeout(() => callCC(), 1000);

        return () => clearTimeout(timer);
    }, []);

    return null;
}
