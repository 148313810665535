const sliderReducer = (state, action) => {
    switch (action.type) {
        case "PROGRESS":
            return {
                ...state,
                masking: true,
            }
        case "GOTO":
            return {
                ...state,
                masking: true,
                nextIndex: action.payload
            };
        case "FINISHANIMATION":
            return {
                ...state,
                masking: false,
                currentIndex: state.nextIndex
            };
        default:
            return state;
    }
}
export default sliderReducer;