import React from "react";
import { Helmet } from "react-helmet";
import Seo from "../components/seo";

const NotFoundPage = () => (
    <>
        <Helmet>
            <body className="blog my404"></body>
        </Helmet>
        <Seo title="404: Not found" />
        <div className="content404">
            <h1>NOT FOUND</h1>
            <p>You just hit an URL that doesn&#39;t have anything to offer... </p>
        </div>
    </>
);

export default NotFoundPage;
