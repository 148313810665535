import React, { useState, useEffect, useContext, useReducer } from "react";
import { Link, navigate } from "gatsby";
import Img from "gatsby-image";
import Navbar from "./Navbar";
import ThemeContext from "../../stores/ThemeContext";
import PagePropsContext from "../../stores/PagePropsContext";
import { useHeroImages } from "../../hooks/useHeroImages";
import SliderReducer from "../../stores/SliderReducer";
// import imagesLoaded from 'react-images-loaded'
import imagesLoaded from "imagesloaded";
import initialThemeState from "../../stores/InitialState";

let CSS_DURATION = 1400;

const Header = () => {
    const isClient = typeof window === "object";
    const { imageArray } = useHeroImages();
    const {
        pageProps: { showHeaderLogo, pageTitle, pageIntroText },
    } = useContext(PagePropsContext);
    const { themeOpt, dispatch } = useContext(ThemeContext);
    const slideLength = initialThemeState.linkArray[themeOpt.lang].length;
    const initialSliderState = {
        currentIndex: 0,
        nextIndex: 0,
        masking: false,
        slideLength: slideLength,
        sleeping: false,
    };
    const carouselArray = [...Array(5).keys()];
    const [loadNow, setloadNow] = useState(false);
    const [state, dispatch2] = useReducer(SliderReducer, initialSliderState);
    // console.log("slideLength", slideLength, "currentIndex", state.currentIndex);
    const [loadedAndDisplayed, setloadedAndDisplayed] = useState(false);
    const [loaded, setloaded] = useState(false);
    const [usersRes, setusersRes] = useState("resolution unknown");

    const [usersSpeed, setusersSpeed] = useState(-1);
    const [loadStatus, setloadStatus] = useState(false);

    const clickPrev = () => {
        const next = (state.currentIndex - 1 + state.slideLength) % state.slideLength;
        console.log("next", next);
        navigate(themeOpt.linkArray[themeOpt.lang][next]);
    };
    const clickNext = () => {
        dispatch2({ type: "GOTO", payload: state.currentIndex + 1 });
        console.log(state.currentIndex, themeOpt.linkArray[themeOpt.lang][state.currentIndex]);
        navigate(themeOpt.linkArray[themeOpt.lang][state.currentIndex]);
        /*         const next = (state.currentIndex + 1) % state.slideLength;
        console.log("next", next);
        navigate(themeOpt.linkArray[themeOpt.lang][next]); */
    };

    useEffect(() => {
        setusersRes("resolution: " + themeOpt.browserWidth + " x " + themeOpt.browserHeight + "px");
    }, [isClient, dispatch, themeOpt.browserWidth, themeOpt.browserHeight]);

    useEffect(() => {
        // check for pageId update
        dispatch2({ type: "GOTO", payload: themeOpt.pageId });
        let timeout = setTimeout(() => {
            dispatch2({ type: "FINISHANIMATION" });
        }, CSS_DURATION);
        return () => clearTimeout(timeout);
    }, [themeOpt.pageId]);

    useEffect(() => {
        setusersSpeed(0);
        let downloadSize = 570000; // Total size of image is 1.5 MB
        let myImage = new Image();
        let startTime = new Date().getTime();
        let cacheBuster = "?nnn=" + startTime;
        myImage.src = "/zada.jpg" + cacheBuster;
        myImage.onload = function () {
            let endTime = new Date().getTime();
            let measureSpeed = () => {
                let duration = (endTime - startTime) / 1000;
                let bitsLoaded = downloadSize * 8;
                let speedBps = (bitsLoaded / duration).toFixed(0);
                let humanSpeed = (speedBps / 1024).toFixed(0);
                humanSpeed = humanSpeed > 1000 ? (humanSpeed / 1024).toFixed() + "Mb/s" : humanSpeed + "Kb/s";
                setusersSpeed(humanSpeed);
                setloadNow(true);
                const imgLoad = imagesLoaded(".project", { background: true }, () => {
                    setloaded(true);
                    setTimeout(() => setloadedAndDisplayed(true), 2000);
                });
                imgLoad.on("progress", (instance, _image) => {
                    setloadStatus("Loading image assets: " + instance.progressedCount + " / " + instance.images.length);
                });
                return humanSpeed;
            };
            console.log(measureSpeed());
        };
    }, []);

    return (
        <>
            {usersSpeed === -1 ? (
                <p className="speedTest"></p>
            ) : usersSpeed === 0 ? (
                <p className="speedTest">Detecting your speed... {usersRes}</p>
            ) : loaded ? (
                <p className="speedTest fadeOut">All done. Have fun! </p>
            ) : loadStatus === false ? (
                <p className="speedTest">
                    Your download speed is {usersSpeed} {loadStatus}.{" "}
                </p>
            ) : (
                <p className="speedTest">{loadStatus} </p>
            )}
            <header className="hero" id="hero">
                {/* isClient &&  */}
                {showHeaderLogo && (
                    <Link to="/" className="logo-sm" key={isClient}>
                        Code 8
                    </Link>
                )}
                <Navbar />
                <div key={pageTitle} className="animHead">
                    <h1 className={`heading${!showHeaderLogo ? " logo" : ""}`}>{pageTitle}</h1>
                    <h2 className="subheading" dangerouslySetInnerHTML={{ __html: pageIntroText }}></h2>
                </div>
                <div className={`c8crsl urban${loadNow ? " loadNow" : ""}${loaded ? " loaded" : ""}${loadedAndDisplayed ? " loadedAndDisplayed" : ""}`}>
                    {carouselArray.map((_item, index) => (
                        <div
                            key={index}
                            className={
                                "project " +
                                (state.currentIndex === index && state.masking
                                    ? "cur masking"
                                    : state.currentIndex === index && !state.masking
                                    ? "cur "
                                    : state.nextIndex === index
                                    ? "nex"
                                    : "")
                            }
                        >
                            <Img fluid={imageArray[index].fluid} imgStyle={{ objectFit: "cover" }} alt={imageArray[index].name} />
                        </div>
                    ))}

                    <div className="project fakie masking"></div>
                    {/*  <nav className="arrows">
                        <button className="arrow previous" onClick={clickPrev} aria-label="Previous">
                            <svg viewBox="208.3 352 4.2 6.4">
                                <polygon className="st0" points="212.1,357.3 211.5,358 208.7,355.1 211.5,352.3 212.1,353 209.9,355.1" />
                            </svg>
                        </button>
                        <button className="arrow next" onClick={clickNext} aria-label="Next">
                            <svg viewBox="208.3 352 4.2 6.4">
                                <polygon className="st0" points="212.1,357.3 211.5,358 208.7,355.1 211.5,352.3 212.1,353 209.9,355.1" />
                            </svg>
                        </button>
                    </nav> */}
                </div>
            </header>
        </>
    );
};
export default Header;
