import React from "react";
import Seo from "../components/seo";
import { Link } from "gatsby";
import Logos from "../components/layout/Logos";
import useIsInViewport from "../hooks/useIsInViewport";

/* START: images import */
import magnus_art_logo from "../img/services/magnus_art_logo.svg";
import elmwood from "../img/services/elmwood.svg";

import mouse_app from "../img/services/mouse_app.svg";
import kohinoor from "../img/services/kohinoor.svg";
//import project_royal_mail from "../img/services/project_royal_mail.svg";
import joalis_logo from "../img/services/logo-joalis.png";
import mpm from "../img/services/mpm.svg";
//import smreflex from "../img/services/smreflex.svg";

import barrocal from "../img/services/barrocal.svg";
import elements from "../img/services/elements.svg";
import every_interaction from "../img/services/every_interaction.png";
import plan_a from "../img/services/plan-a.svg";
//import alpha from "../img/services/alpha.svg";
import hgg from "../img/services/hgg.svg";
import moonwood from "../img/services/moonwood.gif";
import darley_anderson from "../img/services/darley_anderson.svg";
import magic_works from "../img/services/magic_works.svg";
import spell from "../img/services/spell.svg";
//import furnatura from "../img/services/furnatura.svg";
import yogarden from "../img/services/yogarden.svg";

/* END: images import */

const Hospitality = ({ indx, setObserverRef }) => (
    <article className="article" id="hospitality" ref={setObserverRef} data-observer={indx}>
        <article>
            <h2 className="animate-3 h3">Hospitality</h2>
            <div className="examples animate-4">
                <div>
                    <img className="animate-5" src={barrocal} style={{ margin: "28px", height: "78px" }} alt="São Lourenço do Barrocal" />
                    <h4 className="line project animate-6" style={{ color: "#af6824" }}>
                        São Lourenço do Barrocal
                    </h4>
                    <p className="animate-7">
                        São Lourenço do Barrocal, Hotel & Monte Alentejano In the same family for over 200 years, the estate has its heart at the monte, an ancient small farming
                        village, which has been brought carefully back to life as a remarkable hotel of understated luxury, surrounded by ancient holm oaks, olive groves and
                        vineyards.
                    </p>
                    <a className="animate-8" href="https://barrocal.pt" target="_blank" rel="noopener noreferrer">
                        View the site
                    </a>
                </div>
                <div>
                    <img className="animate-5" src={elements} style={{ margin: "20px", maxWidth: "150px", marginTop: "10px" }} alt="Elements" />
                    <h4 className="line project animate-6" style={{ color: "#1d4940" }}>
                        Všetky elementy Tatier
                    </h4>
                    <p className="animate-7">
                        Spoznajte váš druhý domov na horách Náš príbeh začína na úpätí Lomnického štítu, v srdci krásnej a divokej tatranskej prírody. Prírody, ktorá v nás svojou
                        veľkoleposťou budí rešpekt, ale zároveň nás dokáže láskavo objať, uchlácholiť a prinútiť hodiť starosti všedného dňa za hlavu.
                    </p>
                    <a className="animate-8" href="https://elementslomnica.sk" target="_blank" rel="noopener noreferrer">
                        View the site
                    </a>
                </div>
            </div>
        </article>
    </article>
);

const Ecommerce = ({ indx, setObserverRef }) => (
    <article className="article" id="ecommerce" ref={setObserverRef} data-observer={indx}>
        <h2 className="animate-3 h3">E-commerce</h2>
        <div className="examples animate-4">
            <div>
                <img className="animate-5" src={joalis_logo} style={{ margin: "40px", maxWidth: "150px", marginTop: "10px" }} alt="Joalis" />
                <h4 className="line project animate-6">Technological "party of the year"</h4>
                <p className="animate-7">
                    Fully fledged{" "}
                    <a href="https://www.shop8.cz/" target="_blank" rel="noopener noreferrer">
                        shop8
                    </a>{" "}
                    experience — Fine-tuned admin dashboard, powerful GraphQL API, S3 storage, robust deployment pipeline, SSG/SSR website, front shop &amp; mobile app. Multiple
                    languages, multiple product variants, multiple currencies, multiple payment gateways, multiple... You get the point.
                </p>
                <a className="animate-8" href="https://www.joalis.cz" target="_blank" rel="noopener noreferrer">
                    View the site
                </a>
            </div>
            <div>
                <img className="animate-5" src={kohinoor} style={{ margin: "20px", width: "150px" }} alt="Koh-i-noor" />
                <h4 className="line project animate-6">{/* Koh-i-noor Deutschland */}</h4>
                <p className="animate-7">
                    Im Jahr 1790 legte der Hofarchitekt Joseph Hardtmuth den Grundstein für das Unternehmen, aus dem seine Nachkommen, insbesondere sein Sohn Carl und sein Enkel
                    Franz, eines der größten Unternehmen der Welt auf diesem Gebiet machten.
                </p>
                <a className="animate-8" href="https://www.kohinoordeutschland.de" target="_blank" rel="noopener noreferrer">
                    View the site
                </a>
            </div>
        </div>
    </article>
);

const BrandAgencies = ({ indx, setObserverRef }) => (
    <article className="article" id="brandagencies" ref={setObserverRef} data-observer={indx}>
        <h2 className="animate-3 h3">Brand & UX Agecnies</h2>
        <div className="examples animate-4">
            <div>
                <img className="animate-5" src={elmwood} style={{ margin: "32px", width: "240px" }} alt="Elmwood" />
                <h4 className="line project animate-6">Animated presentation</h4>
                <p className="animate-7">
                    One of the best branding companies in the world let us build a snappy web site to cater for their global needs. The site utilizes all the superpowers of Gatsby
                    cloud.
                </p>
                <a className="animate-8" href="https://www.elmwood.com/" target="_blank" rel="noopener noreferrer">
                    View the site
                </a>
            </div>
            <div>
                <img className="animate-5" src={every_interaction} style={{ margin: "45px", maxHeight: "70px" }} alt="Every Interaction" />
                {/* <h4 className="line project animate-6">A dedicated UX Design agency</h4> */}
                <p className="animate-7">
                    You’ll be working iteratively and side-by-side with us to blend your deep domain knowledge with our specialist UX design expertise. We’ll champion the needs of
                    your users at every step, so that your business needs are met in turn.
                </p>
                <a className="animate-8" href="https://www.everyinteraction.com" target="_blank" rel="noopener noreferrer">
                    View the site
                </a>
            </div>
        </div>
    </article>
);

const Collections = ({ indx, setObserverRef }) => (
    <article className="article" id="collections" ref={setObserverRef} data-observer={indx}>
        <h2 className="animate-3 h3">Online collections</h2>
        <div className="examples animate-4">
            <div>
                <img className="animate-5" src={mpm} style={{ margin: "28px", height: "78px" }} alt="DrMouse" />
                <h4 className="line project animate-6">Online catalogue</h4>
                <p className="animate-7">
                    Next.JS allowed us to strike best balance between performance of static file generation and the hassle of building the whole site over and over again. Part of
                    the site is ready (as a static file) up front whilst the dynamic elements stay always up to date.
                </p>
                <a className="animate-8" href="https://www.mobilephonemuseum.com" target="_blank" rel="noopener noreferrer">
                    View the site
                </a>
            </div>
            <div>
                <img className="animate-5" src={magnus_art_logo} style={{ margin: "25px", maxWidth: "300px" }} alt="MagnusArt" />
                <h4 className="line project animate-6">Unique portfolio site</h4>
                <p className="animate-7">
                    Milan likes to manage his portfolio via his favourite CMS however the site is unlikely to be updated frequently. This is a perfect scenario for static site
                    generator sourcing data via API of “behind the scenes” Wordpress instance.
                </p>
                <a className="animate-8" href="https://magnusart.cz" target="_blank" rel="noopener noreferrer">
                    View the site
                </a>
            </div>
        </div>
    </article>
);

const MobileApps = ({ indx, setObserverRef }) => (
    <article className="article" id="mobileApps" ref={setObserverRef} data-observer={indx}>
        <h2 className="animate-3 h3">Mobile Apps</h2>
        <div className="examples animate-4">
            <div>
                <img className="animate-5" src={spell} style={{ margin: "45px", maxHeight: "60px" }} alt="Spell" />
                <h4 className="line project animate-6">Hairdressers</h4>
                <p className="animate-7">
                    Zentiva's program is aimed at patients with multiple sclerosis. The purpose of the app is to help patients manage their disease and provide educational
                    resources that could help improve their quality of life.
                </p>
                <a className="animate-8" href="https://www.spell.cz" target="_blank" rel="noopener noreferrer">
                    Coming soon
                </a>
            </div>
            <div>
                <img className="animate-5" src={mouse_app} style={{ margin: "20px 40px", width: "85px" }} alt="Dr Mouse App" />
                <h4 className="line project animate-6">Dr. Mouse</h4>
                <p className="animate-7">
                    Dr. Mouse is a mobile app for Android and iOS. It maps veterinaries in Czechia and helps you find the right vet for your pet anywhere, even offline. Handy in
                    case of an emergency, don't you think?{" "}
                </p>
                <a className="animate-8" href="https://www.drmouse.cz/aplikace" target="_blank" rel="noopener noreferrer">
                    View the site
                </a>
            </div>
        </div>
    </article>
);

const Architects = ({ indx, setObserverRef }) => (
    <article className="article" id="architects" ref={setObserverRef} data-observer={indx}>
        <h2 className="animate-3 h3">Architect Studios</h2>
        <div className="examples animate-4">
            <div>
                <img className="animate-5" src={plan_a} style={{ margin: "40px", width: "150px" }} alt="Plan A" />
                <h4 className="line project animate-6">Plan A consultants</h4>
                <p className="animate-7">
                    Since 2015, we’ve supported some of the world’s most respected architects with dedicated design management services. Our global, cross-sector expertise includes
                    projects of all shapes and sizes, from transformational urban design to cultural institutions, sports stadiums, airports, universities, and resorts.
                </p>
                <a className="animate-8" href="https://planaconsultants.com" target="_blank" rel="noopener noreferrer">
                    View the site
                </a>
            </div>
            <div>
                <img className="animate-5" src={hgg} style={{ margin: "35px", maxWidth: "200px" }} alt="HGG London" />
                <h4 className="line project animate-6" style={{ color: "#004257" }}>
                    A boutique property development company
                </h4>
                <p className="animate-7">
                    They are committed to mindful development that’s right for London. Their developments positively impact the city, strengthening neighborhoods and communities
                    while respecting the environment.
                </p>
                <a className="animate-8" href="https://hgglondon.co.uk" target="_blank" rel="noopener noreferrer">
                    View the site
                </a>
            </div>
            {/*             <div>
                <img className="animate-5" src={alpha} style={{ margin: "25px", minWidth: "200px" }} alt="Alpha works" />
                <h4 className="line project animate-6" style={{ color: "#003af1" }}>
                    Alpha Works
                </h4>
                <p className="animate-7">Alpha Works is a co-working workspace in Alpha, Birmingham’s iconic modernist tower.</p>
                <a className="animate-8" href="https://www.alphaworksb1.co.uk/" target="_blank" rel="noopener noreferrer">
                    View the site
                </a>
            </div> */}
        </div>
    </article>
);

const Publishing = ({ indx, setObserverRef }) => (
    <article className="article" id="publishing" ref={setObserverRef} data-observer={indx}>
        <h2 className="animate-3 h3">Publishing</h2>
        <div className="examples animate-4">
            <div>
                <img className="animate-5" src={moonwood} style={{ margin: "5px 0 20px", maxWidth: "200px" }} alt="Moonwood books" />
                <h4 className="line project animate-6">Moonwood</h4>
                <p className="animate-7">
                    Moonwood distributes English children's books throughout Europe, directly to schools and parents. It's a family-owned business, based in Prague, Czechia.
                    Founded in 2014 by Zoë Jeremy, their mission is to inspire children to foster a lifelong love of reading.
                </p>
                <a className="animate-8" href="https://www.moonwood-books.com" target="_blank" rel="noopener noreferrer">
                    View the site
                </a>
            </div>
            <div>
                <img className="animate-5" src={darley_anderson} style={{ margin: "40px", width: "150px" }} alt="Darley Anderson" />
                <h4 className="line project animate-6">Darley Anderson</h4>
                <p className="animate-7">
                    Darley Anderson Agency & Associates specializes in bestselling commercial fiction. The DA list includes thrillers, mysteries, suspense, crime, horror,
                    accessible literary, romance, romantic fantasy, sagas and historical.
                </p>
                <a className="animate-8" href="https://darleyanderson.com" target="_blank" rel="noopener noreferrer">
                    View the site
                </a>
            </div>
        </div>
    </article>
);

const ThereSMore = ({ indx, setObserverRef }) => (
    <article className="article" id="thersmore" ref={setObserverRef} data-observer={indx}>
        <h2 className="animate-3 h3">There is more ...</h2>
        <div className="examples animate-4">
            <div>
                <img className="animate-5" src={magic_works} style={{ margin: "40px", width: "150px" }} alt="Magic Works" />
                <h4 className="line project animate-6">Magic Works</h4>
                <p className="animate-7">Training that’s practical, bang up to date, and useful in the real world.</p>
                <a className="animate-8" href="https://magicworks.training" target="_blank" rel="noopener noreferrer">
                    View the site
                </a>
            </div>
            {/*             <div>
                <img className="animate-5" src={furnatura} style={{ margin: "35px", maxWidth: "300px" }} alt="Furnatura" />
                <h4 className="line project animate-6">Natural cosmetic products for dogs</h4>
                <p className="animate-7">The key to a healthy dog coat</p>
                <a className="animate-8" href="https://www.furnatura.com/" target="_blank" rel="noopener noreferrer">
                    View the site
                </a>
            </div> */}
            <div>
                <img className="animate-5" src={yogarden} style={{ margin: "15px", maxWidth: "130px" }} alt="Mondelez" />
                <h4 className="line project animate-6" style={{ color: "#3c4f51" }}>
                    A place for your active relaxation
                </h4>
                <p className="animate-7">The Yogarden is an imaginary garden for your conscious relaxation. Our three halls offer plenty of space to move and breathe.</p>
                <a className="animate-8" href="https://www.yogarden.cz/" target="_blank" rel="noopener noreferrer">
                    View the site
                </a>
            </div>
        </div>
    </article>
);

const Others = ({ indx, setObserverRef }) => (
    <article className="article" id="experience" ref={setObserverRef} data-observer={indx}>
        <div className="text">
            <h2 className="line animate-1">A few big names </h2>
            <p className="animate-2">have trusted us over the years to deliver great products. </p>
        </div>
        <div id="bigClients" className="animate-3">
            <Logos />
        </div>
        <div className="clear"></div>
        <div className="text animate-4" style={{ position: "relative", zIndex: 500 }}>
            <br />
            <p>
                <Link to="/approach/">Find out more about how we work</Link>
            </p>
        </div>
    </article>
);

const sections = [BrandAgencies, Hospitality, Architects, Publishing, MobileApps, Ecommerce, Collections, ThereSMore, Others];

const Projects = () => {
    const { setRef } = useIsInViewport();
    return (
        <>
            <Seo title="Projects" />
            <div>
                {sections.map((Component, index) => (
                    <Component key={index} indx={index} setObserverRef={setRef} />
                ))}
            </div>
            <div className="clear"></div>
        </>
    );
};
export default Projects;
