const initialThemeState = {
    lang: "en",
    menuOpen: false,
    pageId: 0,
    linkArray: {
        en: ["/", "/services/", "/projects/", "/approach/", "/about/", "/contact/"],
        cz: ["/cz/", "/cz/co-umime/", "/cz/projekty/", "/cz/jak-pracujeme/", "/cz/o-nas/", "/cz/kontakt/" /* , "/cz/prace" */],
    },
};
export default initialThemeState;
