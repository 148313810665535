import React from 'react';

const SubLayout = (props) => {
//    console.log(props.children.key)
    return (
        <div id="page" key={props.children.key}>
            <main>
                {props.children}
            </main>
        </div>
    )
};
export default SubLayout;