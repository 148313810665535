import React, { useState } from "react";
import Seo from "../components/seo";
import ReCAPTCHA from "react-google-recaptcha";
import Map from "../components/plugins/Map";
import Loader from "../components/Loader";
import { delay } from "../helpers/helpers";

export default function Contact() {
    const [state, setState] = useState({
        email: "",
        name: "",
        message: "",
        company: "",
        validator: {
            emailValid: true,
            nameValid: true,
            messageValid: true,
        },
        recaptchaValid: false,
        formSent: false,
    });
    const { emailValid, nameValid, messageValid } = state.validator;
    const [loading, setLoading] = useState(false);

    const validateForm = () => {
        const validator = { ...state.validator };
        const { email, name, message } = state;
        const regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        validator.emailValid = email !== "" && regEmail.test(email);
        validator.nameValid = name !== "";
        validator.messageValid = message !== "";

        setState((oldState) => ({ ...oldState, validator }));
        return validator.emailValid && validator.nameValid && validator.messageValid;
    };

    const handleInputChange = (e) => {
        setState((oldState) => ({ ...oldState, [e.target.name]: e.target.value }));
    };
    const handleRecaptchaChange = (_value) => {
        setState((oldState) => ({ ...oldState, recaptchaValid: true }));
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (validateForm() && state.recaptchaValid) {
            const page = document.getElementById("page");
            page.className = "animateOut";
            await delay(1700);
            setLoading(true);
            // collect form data
            const data = {
                name: state.name,
                email: state.email,
                company_name: state.company,
                message: state.message,
            };

            fetch("https://code8.code8.link/api/contact", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }).then((response) => {
                response.json().then((_responseBody) => {
                    if (response.status === 200) {
                        setState((oldState) => ({ ...oldState, formSent: true }));
                        setLoading(false);
                        page.className = "";
                    }
                });
            });
        }
    };

    return (
        <>
            <Seo title="Contact" />
            <h2 className="line sm animate-1">Let's talk work</h2>
            <aside className="animate-9">
                <Map css="animate-10" />
                <h3 className="animate-11">We’re here:</h3>
                <p className="animate-12">
                    Letenske namesti 1, <br />
                    Praha 170 00, <br />
                    Czech republic
                </p>
                <p className="animate-13">
                    <a
                        href="https://www.google.cz/maps/place/Letensk%C3%A9+n%C3%A1m.+156%2F1,+170+00+Praha-Praha+7/@50.1002942,14.4229559,17z/data=!3m1!4b1!4m2!3m1!1s0x470b94c5363e4c41:0x1d54de57ff7350f2"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        View on map
                    </a>
                </p>
                <p className="animate-14">
                    Tel: <a href="tel:+420233313293">+420&nbsp;233&nbsp;313&nbsp;293</a>
                </p>
            </aside>
            {loading ? (
                <Loader />
            ) : !state.formSent ? (
                <form action="https://www.code8.cz/contact.php" method="post" onSubmit={handleFormSubmit} noValidate className="animate-2">
                    <fieldset>
                        <label className="animate-3">
                            Your name <input type="text" name="name" className={`req ${!nameValid && "nogood"}`} onChange={handleInputChange} />
                        </label>
                        <label className="animate-4">
                            Your company name <input type="text" name="company" onChange={handleInputChange} />
                        </label>
                        <label className="fw animate-5">
                            How can we help? <textarea name="message" className={`req ${!messageValid && "nogood"}`} onChange={handleInputChange}></textarea>
                        </label>
                        <label className="animate-6">
                            Your email <input type="email" name="email" className={`req ${!emailValid && "nogood"}`} onChange={handleInputChange} />
                        </label>
                        <ReCAPTCHA
                            sitekey="6LcQLk8UAAAAAMkLWmk8UrD3ofeLNORz7KRnhrnU"
                            className="g-recaptcha animate-7"
                            style={{ display: "inline-block", padding: "0 2em 2em 0", marginTop: "7px" }}
                            onChange={handleRecaptchaChange}
                        />
                        <div className="clear"></div>
                        <button type="submit" onChange={handleFormSubmit} className="animate-8">
                            Get in touch
                        </button>
                    </fieldset>
                </form>
            ) : (
                <div className="m ok animate-1">
                    THANK YOU!
                    <br />
                    You will be hearing from us shortly...
                </div>
            )}
            <div className="clear"></div>
        </>
    );
}
