import React from "react";
import Seo from "../components/seo";
import { Link } from "gatsby";
import Carousel from "../components/layout/Carousel";
import useIsInViewport from "../hooks/useIsInViewport";

/* START: images import */
import head_lukas from "../img/about/head_lukas.jpg";
import head_peter from "../img/about/head_peter.jpg";
import head_bob from "../img/about/head_bob.jpg";
import deloittece from "../img/partners/deloittece.svg";
import every_interaction from "../img/partners/every_interaction.svg";
import and_agency from "../img/partners/agency.svg";
// import lighthouse from "../img/partners/lighthouse.svg";
import imao from "../img/partners/imao.svg";
import open_culture from "../img/partners/open_culture.svg";
import toman_design from "../img/partners/toman.svg";
import milan from "../img/partners/milann.svg";
import friendly from "../img/partners/friendly.svg";
/* END: images import */

const carouselItems = [
    { src: deloittece, alt: "Deloitte CE", href: "http://www.deloittece.com" },
    { src: every_interaction, alt: "Every Interaction", href: "http://www.everyinteraction.com" },
    { src: toman_design, alt: "Toman Desing", href: "http://www.toman-design.com/en" },
    { src: and_agency, alt: "& Agency", href: "http://andagency.co.uk" },
    { src: imao, alt: "Imao", href: "http://imao.cz" },
    { src: open_culture, alt: "Openculture", href: "https://www.opencultu.re" },
    { src: milan, alt: "Milan Nedved ", href: "https://milann.info" },
    { src: friendly, alt: "Friendly", href: "https://www.friendlydigital.cz" },
];

const WhoWeAre = ({ indx, setObserverRef }) => (
    <article className="article" ref={setObserverRef} data-observer={indx}>
        <h2 className="line sm animate-1">Who are we?</h2>
        <blockquote className="desktop">
            <p className="animate-5">
                Code 8’s level of service and expertise has always been second to none. For 5 years they’ve consistently proved they can handle any size and scope of project we may
                give them which makes them an obvious choice and a valuable resource for us.
            </p>
            <em className="animate-6">John Murray, Charm</em>
        </blockquote>
        <article>
            <p className="animate-2">
                Code 8 was established in 2012, we are currently made up of seven full time developers with a further four as part of our extended network.{" "}
            </p>
            <p className="animate-3">
                The founders started their careers back in the age of Internet Explorer 4 and have seen the world of the web transition through most of it’s major iterations, so we
                understand what browser support means.
            </p>
            <p className="animate-4">
                Back in the early 2000’s we had some fun building fancy Adobe Flash sites, but welcomed the rise of web accessibility that followed and returned to our HTML roots.
                We were experimenting with AJAX (via hidden iframes), Progressive Enhancements and Responsive Design before these became widely adopted. This is not to boast, this
                is to reflect our passion for the work we do, and making sure we have the right tool kit with which to solve the problems we need to deliver on.
            </p>
            <p className="animate-7">
                In March 2020 Robert joined the team to open a new dimension to what kind of service Code 8 could provide. A multiskilled product design person is something we've
                been eying since early days and clearly, good things come to those who wait.
            </p>
            <p className="animate-8">
                Having previously worked both client-side and in agency environments in the UK we understand how different teams like to operate and how this has evolved over the
                years. We understand that communication is key to effective team work and endeavour to keep comms high through the lifecycle of all projects.{" "}
            </p>
        </article>
    </article>
);

const Founders = ({ indx, setObserverRef }) => (
    <article className="article founders animate-1" ref={setObserverRef} data-observer={indx}>
        <h3 className="animate-2">Board of wizards</h3>
        <div className="animate-3">
            <img className="animation-up" src={head_lukas} alt="Lukas Vacovsky" />
            <h4 className="animate-4">Lukas Vacovsky</h4>
            <h5 className="animate-5">Head of Front End</h5>
            <p className="animate-6">
                Has spent 7 years working for various agencies in London. He gets a bit particular about code quality, open internet and places to eat pizza. But he also believes
                that for every problem there is a solution and he won’t rest until he’s satisfied it’s been solved properly.
            </p>
            <a className="animate-7" href="mailto:lukas@code8.cz">
                Contact Lukas
            </a>
        </div>
        <div className="animate-3">
            <img className="animation-up" src={head_peter} alt="Petr Ocenasek" />
            <h4 className="animate-4">Petr Ocenasek</h4>
            <h5 className="animate-5">Head of Back End</h5>
            <p className="animate-6">
                "Burt" met Lukas on the verge of the new millennium in an internet start-up called Samba digital media. At this time he was architecting and coding complex PHP
                solutions for large corporate clients, busy drawing UML diagrams and programming remote controlled toys for fun.
            </p>
            <a className="animate-7" href="mailto:petr@code8.cz">
                Contact Petr
            </a>
        </div>
        <div className="animate-3">
            <img className="animation-up" src={head_bob} alt="Robert Kalocai" />
            <h4 className="animate-4">Robert Kalocai</h4>
            <h5 className="animate-5">Head of Product Design</h5>
            <p className="animate-6">
                Has been accumulating experience in the Bay area of San Francisco and his revolutionary Nanits Comics received a lot of attention worldwide. With his unstoppable
                appetite for great product design and vast area of expertise we are delighted to have him onboard.
            </p>
            <a className="animate-7" href="mailto:robert@code8.cz">
                Contact Robert
            </a>
        </div>
    </article>
);

const Code8Born = ({ indx, setObserverRef }) => (
    <article className="article" ref={setObserverRef} data-observer={indx}>
        <h2 className="line sm animate-1">Code 8 was born</h2>
        <p className="animate-2">
            When Lukas returned to his homeland in late 2011, the work followed him back and he reconnected with Burt to start delivering a wider range of services to his clients.
            With such complementary skill sets the relationship bloomed and the clients kept coming back in bigger numbers. Boosted by skill shortage in the UK that made getting
            reliable and quality dev partners difficult, the team soon needed to expand into the team they are today.{" "}
        </p>
    </article>
);

const Experience = ({ indx, setObserverRef }) => (
    <article className="article" id="experience" ref={setObserverRef} data-observer={indx}>
        <div className="text">
            <h2 className="line animate-1">Partners</h2>
            <p className="animate-2">We have some great partners that can provide Hosting, Web Design, UX and branding.</p>
        </div>
        <div className="animate-3" id="experienced">
            <Carousel items={carouselItems} />
        </div>
        <div className="clear"></div>
        <div className="text animate-4">
            <br />
            <p>
                <Link to="/contact/">Get in touch</Link>
            </p>
        </div>
    </article>
);

const sections = [WhoWeAre, Founders, Code8Born, Experience];

const About = () => {
    const { setRef } = useIsInViewport();
    return (
        <>
            <Seo title="About us" />
            <div>
                {sections.map((Component, index) => (
                    <Component key={index} indx={index} setObserverRef={setRef} />
                ))}
            </div>
            <div className="clear"></div>
        </>
    );
};

export default About;
