import React from "react";
import PropTypes from "prop-types";

import tickGrey from "../img/tick-grey.svg";
import tickBlue from "../img/tick-blue.svg";
import Carousel from "./layout/Carousel";
import { Link } from "gatsby";

export const referenceWorkAreasEn = {
    ideaMaking: "Idea making",
    analysis: "Analysis",
    productDesign: "Product Design",
    userExperience: "User Experience",
    userInterface: "User Interface",
    frontEnd: "Front-end",
    backEnd: "Back-end",
    marketingPromo: "Marketing & promotion",
};

export const referenceWorkAreasCs = {
    ideaMaking: "Tvorba nápadu",
    analysis: "Analýza",
    productDesign: "Design produktu",
    userExperience: "UX",
    userInterface: "UI",
    frontEnd: "Front-end",
    backEnd: "Back-end",
    marketingPromo: "Marketing & promo",
};

const Reference = (props) => {
    const lang = "lang" in props && typeof props.lang === "string" ? props.lang : "en";
    const areas = "areas" in props && typeof props.areas === "object" ? props.areas : {};
    const desc = "desc" in props && props.desc !== null && props.desc !== undefined ? props.desc : null;
    const comment = "comment" in props && typeof props.comment === "object" ? props.comment : null;
    const product = "product" in props && typeof props.product === "object" ? props.product : null;
    const technologies = "technologies" in props && typeof props.technologies === "object" ? props.technologies : null;
    const actions = "actions" in props && Array.isArray(props.actions) && props.actions.length > 0 ? props.actions : null;

    const referenceWorkAreas = lang === "cs" ? referenceWorkAreasCs : referenceWorkAreasEn;

    return (
        <div className="reference-skeleton">
            <div className="article" id="areas">
                <article>
                    <h2 className="line sm">{lang === "cs" ? "Oblasti, ve kterých jsme pracovali" : "Areas in which we worked"}</h2>
                </article>

                <article>
                    <div className="flex-container">
                        {Object.keys(referenceWorkAreas).map((area) => {
                            const isActive = area in areas && areas[area];

                            return (
                                <div className={`area ${isActive ? "colored" : ""}`} key={area}>
                                    <span>{referenceWorkAreas[area]}</span>
                                    <img src={isActive ? tickBlue : tickGrey} alt="Tick icon" />
                                </div>
                            );
                        })}
                    </div>
                </article>
            </div>

            {desc !== null ? (
                <div className="article" id="problem">
                    <article>
                        <h2 className="line sm">{lang === "cs" ? "Problém, který jsme vyřešili" : "The problem we solved"}</h2>
                    </article>

                    {comment !== null ? (
                        <div>
                            <blockquote className="desktop noq">
                                {comment.text}
                                <em>{comment.author}</em>
                            </blockquote>
                        </div>
                    ) : null}

                    <article>{desc}</article>
                </div>
            ) : null}

            {product !== null ? (
                <div className="article" id="product">
                    <article>
                        <h2 className="line sm">{lang === "cs" ? "Produkt, který jsme vytvořili" : "The product we created"}</h2>
                    </article>

                    {"image" in product ? (
                        <div className="side-image">
                            <img src={product.image.src} alt={product.image.alt} />
                        </div>
                    ) : null}

                    {"desc" in product ? <article className="desc">{product.desc}</article> : null}

                    {"galleryImages" in product && Array.isArray(product.galleryImages) && product.galleryImages.length > 0 ? (
                        <div className="gallery">
                            <div className="flex-container">
                                {product.galleryImages.map((image, i) => (
                                    <img src={image.src} alt={image.alt} key={`image-${i}`} />
                                ))}
                            </div>
                        </div>
                    ) : null}

                    {"sections" in product && Array.isArray(product.sections) && product.sections.length > 0 ? (
                        <div className="sections">
                            <div className="flex-container">
                                {product.sections.map((section, i) => (
                                    <div className={`section size-${section.size}`} key={`section-${i}`}>
                                        {"icon" in section && section.icon !== null && section.icon !== undefined ? (
                                            <div className="icon">
                                                <img src={section.icon.src} alt={section.icon.alt} />
                                            </div>
                                        ) : null}

                                        {"title" in section && section.title !== null && section.title !== undefined ? <h3 className="title">{section.title}</h3> : null}

                                        {"desc" in section && section.desc !== null && section.desc !== undefined ? <div className="desc">{section.desc}</div> : null}

                                        {"actions" in section && Array.isArray(section.actions) && section.actions.length > 0 ? (
                                            <div className="actions">
                                                <div className="flex-container">
                                                    {section.actions.map((action, i) => (
                                                        <Link to={action.to} className="action" key={`action-${i}`}>
                                                            {action.title}
                                                        </Link>
                                                    ))}
                                                </div>
                                            </div>
                                        ) : null}

                                        {"image" in section && section.image !== null && section.image !== undefined ? (
                                            <div className="image">
                                                <img src={section.image.src} alt={section.image.alt} />
                                            </div>
                                        ) : null}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : null}
                </div>
            ) : null}

            {technologies !== null ? (
                <div className="article" id="technologies">
                    <article>
                        <h2 className="line sm">{lang === "cs" ? "Techologie, které jsme použili" : "The techologies we used"}</h2>
                    </article>

                    {"desc" in technologies ? <article>{technologies.desc}</article> : null}

                    {"icons" in technologies && Array.isArray(technologies.icons) && technologies.icons.length > 0 ? (
                        <div id="experienced">
                            <Carousel items={technologies.icons} />
                        </div>
                    ) : null}
                </div>
            ) : null}

            {actions !== null ? (
                <div className="article" id="actions">
                    <div className="flex-container">
                        {actions.map((action, i) => (
                            <Link to={action.to} className="action" key={`action-${i}`}>
                                {action.title}
                            </Link>
                        ))}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

const imagePropType = PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
});

const linkPropType = PropTypes.shape({
    to: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
});

Reference.propTypes = {
    lang: PropTypes.string.isRequired,
    areas: PropTypes.shape({
        ideaMaking: PropTypes.bool,
        analysis: PropTypes.bool,
        productDesign: PropTypes.bool,
        userExperience: PropTypes.bool,
        userInterface: PropTypes.bool,
        frontEnd: PropTypes.bool,
        backEnd: PropTypes.bool,
        marketingPromo: PropTypes.bool,
    }),
    desc: PropTypes.element,
    comment: PropTypes.shape({
        text: PropTypes.element.isRequired,
        author: PropTypes.string.isRequired,
    }),
    product: PropTypes.shape({
        desc: PropTypes.element,
        image: imagePropType,
        galleryImages: PropTypes.arrayOf(imagePropType),
        sections: PropTypes.arrayOf(
            PropTypes.shape({
                size: PropTypes.oneOf(["half", "full"]),
                icon: imagePropType,
                title: PropTypes.string,
                desc: PropTypes.element,
                actions: PropTypes.arrayOf(linkPropType),
                image: imagePropType,
            })
        ),
    }),
    technologies: PropTypes.shape({
        desc: PropTypes.element,
        icon: PropTypes.arrayOf(imagePropType),
    }),
    actions: PropTypes.arrayOf(linkPropType),
};

export default Reference;
