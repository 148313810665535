import React, { useContext } from "react";
import { Link } from "gatsby";
import ThemeContext from "../../stores/ThemeContext";

const preventBtnHandler = (e) => e.preventDefault();

const Footer = () => {
    let { themeOpt } = useContext(ThemeContext);
    //  console.log(themeOpt);
    return (
        <>
            {themeOpt.lang === "cz" && (
                <footer>
                    <ul>
                        <li>
                            <Link to="/cz/co-umime/" activeClassName="active">
                                co umíme
                            </Link>
                        </li>
                        <li>
                            <Link to="/cz/projekty/" activeClassName="active">
                                reference
                            </Link>
                        </li>
                        <li>
                            <Link to="/cz/jak-pracujeme/" activeClassName="active">
                                jak pracujeme
                            </Link>
                        </li>
                        <li>
                            <Link to="/cz/o-nas/" activeClassName="active">
                                o nás
                            </Link>
                        </li>
                        <li>
                            <Link to="/cz/kontakt/" activeClassName="active">
                                kontakt
                            </Link>
                        </li>
                        {/*<li><Link to='/home'>English</Link></li>*/}
                    </ul>
                    <hr />
                    <div className="bottom">
                        <div>
                            <p>Code Eight, s.r.o. DIČ: CZ24852261</p>
                            <button type="button" className="cookieConset" data-cc="c-settings" onClick={preventBtnHandler}>
                                Show cookie settings
                            </button>
                        </div>
                        <p>Letenské náměstí 1, Praha 170 00</p>
                    </div>
                    <button type="button" className="cookieConset mobile" data-cc="c-settings" onClick={preventBtnHandler}>
                        Show cookie settings
                    </button>
                </footer>
            )}
            {themeOpt.lang === "en" && (
                <footer>
                    <ul>
                        <li>
                            <Link to="/services/" activeClassName="active">
                                what we do
                            </Link>
                        </li>
                        <li>
                            <Link to="/projects/" activeClassName="active">
                                our work
                            </Link>
                        </li>
                        <li>
                            <Link to="/approach/" activeClassName="active">
                                approach
                            </Link>
                        </li>
                        <li>
                            <Link to="/about/" activeClassName="active">
                                about
                            </Link>
                        </li>
                        <li>
                            <Link to="/contact/" activeClassName="active">
                                contact
                            </Link>
                        </li>
                        {/*<li><Link to='/cz/'>Česky</Link></li>*/}
                    </ul>
                    <hr />
                    <div className="bottom">
                        <div>
                            <p>Code Eight, s.r.o. DIČ (VAT): CZ24852261</p>
                            <button type="button" className="cookieConset" data-cc="c-settings" onClick={preventBtnHandler}>
                                Show cookie settings
                            </button>
                        </div>
                        <p>Letenske namesti 1, Praha 170 00, Czech Republic</p>
                    </div>
                    <button type="button" className="cookieConset mobile" data-cc="c-settings" onClick={preventBtnHandler}>
                        Show cookie settings
                    </button>
                </footer>
            )}
        </>
    );
};
export default Footer;
