import React from "react";
import Seo from "../../components/seo";
import { Link } from "gatsby";
import Logos from "../../components/layout/Logos";
import useIsInViewport from "../../hooks/useIsInViewport";
// import Tooltip from '../plugins/Tooltip';

/* START: images import */
import magnus_art_logo from "../../img/services/magnus_art_logo.svg";
import elmwood from "../../img/services/elmwood.svg";
//import zpevnik from "../../img/services/zpevnik.svg";
import mouse_app from "../../img/services/mouse_app.svg";
import kohinoor from "../../img/services/kohinoor.svg";
//import project_royal_mail from "../../img/services/project_royal_mail.svg";
//import project_mondelez from "../../img/services/project_mondelez.svg";
import joalis_logo from "../../img/services/logo-joalis.png";
import mpm from "../../img/services/mpm.svg";

import barrocal from "../../img/services/barrocal.svg";
import elements from "../../img/services/elements.svg";
import every_interaction from "../../img/services/every_interaction.png";
import plan_a from "../../img/services/plan-a.svg";
//import alpha from "../../img/services/alpha.svg";
import hgg from "../../img/services/hgg.svg";
import moonwood from "../../img/services/moonwood.gif";
import darley_anderson from "../../img/services/darley_anderson.svg";
import magic_works from "../../img/services/magic_works.svg";
import spell from "../../img/services/spell.svg";
//import furnatura from "../../img/services/furnatura.svg";
import yogarden from "../../img/services/yogarden.svg";

const Hospitality = ({ indx, setObserverRef }) => (
    <article className="article" id="hospitality" ref={setObserverRef} data-observer={indx}>
        <article>
            <h2 className="animate-3 h3">Pohostinství</h2>
            <div className="examples animate-4">
                <div>
                    <img className="animate-5" src={barrocal} style={{ margin: "28px", height: "78px" }} alt="São Lourenço do Barrocal" />
                    <h4 className="line project animate-6" style={{ color: "#af6824" }}>
                        São Lourenço do Barrocal
                    </h4>
                    <p className="animate-7">
                        São Lourenço do Barrocal, Hotel & Monte Alentejano Srdcem panství, které patří stejné rodině již více než 200 let, je Monte, starobylá zemědělská vesnička,
                        která byla pečlivě oživena jako pozoruhodný hotel s decentním luxusem, obklopený starými duby, olivovými háji a vinicemi.
                    </p>
                    <a className="animate-8" href="https://barrocal.pt" target="_blank" rel="noopener noreferrer">
                        Navštivte web
                    </a>
                </div>
                <div>
                    <img className="animate-5" src={elements} style={{ margin: "20px", maxWidth: "150px", marginTop: "10px" }} alt="Elements" />
                    <h4 className="line project animate-6" style={{ color: "#1d4940" }}>
                        Všetky elementy Tatier
                    </h4>
                    <p className="animate-7">
                        Spoznajte váš druhý domov na horách Náš príbeh začína na úpätí Lomnického štítu, v srdci krásnej a divokej tatranskej prírody. Prírody, ktorá v nás svojou
                        veľkoleposťou budí rešpekt, ale zároveň nás dokáže láskavo objať, uchlácholiť a prinútiť hodiť starosti všedného dňa za hlavu.
                    </p>
                    <a className="animate-8" href="https://elementslomnica.sk" target="_blank" rel="noopener noreferrer">
                        Navštivte web
                    </a>
                </div>
            </div>
        </article>
    </article>
);

const Ecommerce = ({ indx, setObserverRef }) => (
    <article className="article" id="ecommerce" ref={setObserverRef} data-observer={indx}>
        <h2 className="animate-3 h3">E-commerce</h2>
        <div className="examples animate-4">
            <div>
                <img className="animate-5" src={joalis_logo} style={{ margin: "40px", maxWidth: "150px", marginTop: "10px" }} alt="Joalis" />
                <h4 className="line project animate-6">Technologický "večírek roku"</h4>
                <p className="animate-7">
                    Plnohodnotné prostředí{" "}
                    <a href="https://www.shop8.cz/" target="_blank" rel="noopener noreferrer">
                        shop8
                    </a>{" "}
                    - vyladěný panel správce, výkonné rozhraní GraphQL API, úložiště S3, robustní pipeline, webové stránky SSG/SSR, samotný eshop a mobilní aplikace. Více jazyků,
                    více variant produktů, více měn, více platebních bran, více... Rozumíme si. :)
                </p>
                <a className="animate-8" href="https://www.joalis.cz" target="_blank" rel="noopener noreferrer">
                    Navštivte web
                </a>
            </div>
            <div>
                <img className="animate-5" src={kohinoor} style={{ margin: "20px", width: "150px" }} alt="Koh-i-noor" />
                <h4 className="line project animate-6">{/* Koh-i-noor Deutschland */}</h4>
                <p className="animate-7">
                    Im Jahr 1790 legte der Hofarchitekt Joseph Hardtmuth den Grundstein für das Unternehmen, aus dem seine Nachkommen, insbesondere sein Sohn Carl und sein Enkel
                    Franz, eines der größten Unternehmen der Welt auf diesem Gebiet machten.
                </p>
                <a className="animate-8" href="https://www.kohinoordeutschland.de" target="_blank" rel="noopener noreferrer">
                    Navštivte web
                </a>
            </div>
        </div>
    </article>
);

const BrandAgencies = ({ indx, setObserverRef }) => (
    <article className="article" id="brandagencies" ref={setObserverRef} data-observer={indx}>
        <h2 className="animate-3 h3">Brand & UX agentůry</h2>
        <div className="examples animate-4">
            <div>
                <img className="animate-5" src={elmwood} style={{ margin: "32px", width: "240px" }} alt="Elmwood" />
                <h4 className="line project animate-6">Animovaná prezentace</h4>
                <p className="animate-7">
                    Jedna z nejlepších společností na světě, která se zabývá tvorbou značek, si od nás nechala vytvořit svižný web pro své globální potřeby. Web využívá všech
                    superschopností cloudu Gatsby.
                </p>
                <a className="animate-8" href="https://www.elmwood.com/" target="_blank" rel="noopener noreferrer">
                    Navštivte web
                </a>
            </div>
            <div>
                <img className="animate-5" src={every_interaction} style={{ margin: "45px", maxHeight: "70px" }} alt="Every Interaction" />
                {/* <h4 className="line project animate-6">Naši přátelé z UK</h4> */}
                <p className="animate-7">
                    Budete s námi pracovat iterativně a bok po boku, abyste spojili své hluboké znalosti v oboru s našimi odbornými znalostmi v oblasti designu UX. Na každém kroku
                    budeme hájit potřeby vašich uživatelů, aby byly na oplátku splněny vaše obchodní potřeby.
                </p>
                <a className="animate-8" href="https://www.everyinteraction.com" target="_blank" rel="noopener noreferrer">
                    Navštivte web
                </a>
            </div>
        </div>
    </article>
);

const Collections = ({ indx, setObserverRef }) => (
    <article className="article" id="collections" ref={setObserverRef} data-observer={indx}>
        <h2 className="animate-3 h3">Digitální kolekce</h2>
        <div className="examples animate-4">
            <div>
                <img className="animate-5" src={mpm} style={{ margin: "28px", height: "78px" }} alt="DrMouse" />
                <h4 className="line project animate-6">Online katalog</h4>
                <p className="animate-7">
                    Next.JS nám umožnil najít nejlepší rovnováhu mezi výkonem generování statických souborů a obtížným vytvářením celého webu znovu a znovu. Část webu je připravena
                    (jako statické soubory) předem, zatímco dynamické prvky zůstávají stále aktualizované.
                </p>
                <a className="animate-8" href="https://www.mobilephonemuseum.com" target="_blank" rel="noopener noreferrer">
                    Navštivte web
                </a>
            </div>
            <div>
                <img className="animate-5" src={magnus_art_logo} style={{ margin: "25px", maxWidth: "300px" }} alt="MagnusArt" />
                <h4 className="line project animate-6">Unique portfolio site</h4>
                <p className="animate-7">
                    Klient si přál spravovat obsah webu přes svůj oblíbený CMS, ale bylo velmi pravděpodobné, že stránky nebudou aktualizované příliš často. To je ideální scenář
                    pro použití generátoru statických stránek, který by si pro data šahal do externí Wordpress instance.
                </p>
                <a className="animate-8" href="https://magnusart.cz" target="_blank" rel="noopener noreferrer">
                    Navštivte web
                </a>
            </div>
        </div>
    </article>
);

const MobileApps = ({ indx, setObserverRef }) => (
    <article className="article" id="mobileApps" ref={setObserverRef} data-observer={indx}>
        <h2 className="animate-3 h3">Mobilní Apky</h2>
        <div className="examples animate-4">
            <div>
                <img className="animate-5" src={spell} style={{ margin: "45px", maxHeight: "60px" }} alt="Spell" />
                <h4 className="line project animate-6">Hairdressers</h4>
                <p className="animate-7">
                    Zentiva's program is aimed at patients with multiple sclerosis. The purpose of the app is to help patients to manage their disease and provide educational
                    resources which could help to improve their quality of life.
                </p>
                <a className="animate-8" href="https://www.spell.cz" target="_blank" rel="noopener noreferrer">
                    Coming soon
                </a>
            </div>
            <div>
                <img className="animate-5" src={mouse_app} style={{ margin: "20px 40px", width: "85px" }} alt="Dr Mouse App" />
                <h4 className="line project animate-6">Dr. Mouse</h4>
                <p className="animate-7">
                    Dr. Mouse je mobilní aplikace pro Android a iOS. Mapuje veterináře v Česku a pomůže vám kdekoli najít toho správného veterináře pro vašeho mazlíčka. Dokonce i
                    offline. Šikovné v případě nouze, nemyslíte?
                </p>
                <a className="animate-8" href="https://www.drmouse.cz/aplikace" target="_blank" rel="noopener noreferrer">
                    Navštivte web
                </a>
            </div>
        </div>
    </article>
);

const Architects = ({ indx, setObserverRef }) => (
    <article className="article" id="architects" ref={setObserverRef} data-observer={indx}>
        <h2 className="animate-3 h3">Architektonická Studia</h2>
        <div className="examples animate-4">
            <div>
                <img className="animate-5" src={plan_a} style={{ margin: "40px", width: "150px" }} alt="Plan A" />
                <h4 className="line project animate-6">Plan A consultants</h4>
                <p className="animate-7">
                    Od roku 2015 podporujeme nejuznávanější světové architekty specializovanými službami správy návrhů. Naše globální, meziodvětvové zkušenosti zahrnují projekty
                    všech tvarů a velikostí, od transformačního urbanistického designu po kulturní instituce, sportovní stadiony, letiště, univerzity a letoviska.
                </p>
                <a className="animate-8" href="https://planaconsultants.com" target="_blank" rel="noopener noreferrer">
                    Navštivte web
                </a>
            </div>
            <div>
                <img className="animate-5" src={hgg} style={{ margin: "35px", maxWidth: "200px" }} alt="HGG London" />
                <h4 className="line project animate-6" style={{ color: "#004257" }}>
                    Butiková developerská společnost
                </h4>
                <p className="animate-7">
                    Zasazují se o ohleduplný rozvoj, který je pro Londýn vhodný. Jejich projekty mají pozitivní dopad na město, posilují čtvrti a komunity a zároveň respektují
                    životní prostředí.
                </p>
                <a className="animate-8" href="https://hgglondon.co.uk" target="_blank" rel="noopener noreferrer">
                    Navštivte web
                </a>
            </div>
            {/*             <div>
                <img className="animate-5" src={alpha} style={{ margin: "25px", minWidth: "200px" }} alt="Alpha works" />
                <h4 className="line project animate-6" style={{ color: "#003af1" }}>
                    Alpha Works
                </h4>
                <p className="animate-7">Alpha Works is a co-working workspace in Alpha, Birmingham’s iconic modernist tower.</p>
                <a className="animate-8" href="https://www.alphaworksb1.co.uk/" target="_blank" rel="noopener noreferrer">
                    Navštivte web
                </a>
            </div> */}
        </div>
    </article>
);

const Publishing = ({ indx, setObserverRef }) => (
    <article className="article" id="publishing" ref={setObserverRef} data-observer={indx}>
        <h2 className="animate-3 h3">Publishing</h2>
        <div className="examples animate-4">
            <div>
                <img className="animate-5" src={moonwood} style={{ margin: "5px 0 20px", maxWidth: "200px" }} alt="Moonwood books" />
                <h4 className="line project animate-6">Moonwood</h4>
                <p className="animate-7">
                    Moonwood distribuuje anglické dětské knihy po celé Evropě, a to přímo do škol a rodičům. Je to rodinný podnik se sídlem v Praze. Založila ji v roce 2014 Zoë
                    Jeremy a jejím posláním je inspirovat děti k celoživotní lásce ke čtení.
                </p>
                <a className="animate-8" href="https://www.moonwood-books.com" target="_blank" rel="noopener noreferrer">
                    Navštivte web
                </a>
            </div>
            <div>
                <img className="animate-5" src={darley_anderson} style={{ margin: "40px", width: "150px" }} alt="Darley Anderson" />
                <h4 className="line project animate-6">Darley Anderson</h4>
                <p className="animate-7">
                    Darley Anderson Agency & Associates se specializuje na bestsellery komerční beletrie. Seznam DA zahrnuje thrillery, záhady, napětí, krimi, horory, přístupnou
                    literaturu, romance, romantickou fantasy, ságy a historické romány.
                </p>
                <a className="animate-8" href="https://darleyanderson.com" target="_blank" rel="noopener noreferrer">
                    Navštivte web
                </a>
            </div>
        </div>
    </article>
);

const ThereSMore = ({ indx, setObserverRef }) => (
    <article className="article" id="thersmore" ref={setObserverRef} data-observer={indx}>
        <h2 className="animate-3 h3">Je toho více ...</h2>
        <div className="examples animate-4">
            <div>
                <img className="animate-5" src={magic_works} style={{ margin: "40px", width: "150px" }} alt="Magic Works" />
                <h4 className="line project animate-6">Magic Works</h4>
                <p className="animate-7">Školení, které je praktické, aktuální a užitečné v reálném světě.</p>
                <a className="animate-8" href="https://magicworks.training" target="_blank" rel="noopener noreferrer">
                    Navštivte web
                </a>
            </div>
            {/*             <div>
                <img className="animate-5" src={furnatura} style={{ margin: "35px", maxWidth: "300px" }} alt="Furnatura" />
                <h4 className="line project animate-6">Natural cosmetic products for dogs</h4>
                <p className="animate-7">The key to a healthy dog coat</p>
                <a className="animate-8" href="https://www.furnatura.com/" target="_blank" rel="noopener noreferrer">
                    Navštivte web
                </a>
            </div> */}
            <div>
                <img className="animate-5" src={yogarden} style={{ margin: "15px", maxWidth: "130px" }} alt="Mondelez" />
                <h4 className="line project animate-6" style={{ color: "#3c4f51" }}>
                    Místo pro váš aktivní odpočinek
                </h4>
                <p className="animate-7">Yogarden je pomyslná zahrada pro váš vědomý odpočinek. Naše tři sály skýtají dostatek prostoru k pohybu i nadechnutí.</p>
                <a className="animate-8" href="https://www.yogarden.cz" target="_blank" rel="noopener noreferrer">
                    Navštivte web
                </a>
            </div>
        </div>
    </article>
);

const Others = ({ indx, setObserverRef }) => (
    <article className="article" id="experience" ref={setObserverRef} data-observer={indx}>
        <div className="text">
            {/* <h2 className="line animate-1">Vážíme si každé spolupráce</h2> */}
            <h2 className="line animate-1">Důvěřují nám velké firmy</h2>
            <p className="animate-2">i to nám dává motivaci do dalších projektů. </p>
        </div>
        <div id="bigClients" className="animate-3">
            <Logos />
        </div>
        <div className="clear"></div>
        <div className="text animate-4" style={{ position: "relative", zIndex: 500 }}>
            <br />
            <p>
                <Link to="/cz/jak-pracujeme/">Zjistěte víc o tom jak pracujeme</Link>
            </p>
        </div>
    </article>
);

const sections = [Hospitality, Publishing, Architects, MobileApps, BrandAgencies, Ecommerce, Collections, ThereSMore, Others];

const Projects = () => {
    const { setRef } = useIsInViewport();
    return (
        <>
            <Seo title="Reference" />
            <div>
                {sections.map((Component, index) => (
                    <Component key={index} indx={index} setObserverRef={setRef} />
                ))}
            </div>
            <div className="clear"></div>
        </>
    );
};
export default Projects;
