import { useStaticQuery, graphql } from "gatsby";

export const useHeroImages = () => {
    const { allFile } = useStaticQuery(
        graphql`
            query heroImages {
                allFile(sort: { fields: relativePath, order: ASC }) {
                    nodes {
                        childImageSharp {
                            fluid(quality: 80) {
                                presentationHeight
                                sizes
                                src
                                srcSet
                                presentationWidth
                                aspectRatio
                                originalName
                            }
                        }
                        name
                    }
                }
            }
        `
    );
    const imageArray = allFile.nodes.map(({ childImageSharp, name }) => ({ ...childImageSharp, name: name }));
    return { imageArray: imageArray };
};
