import React from "react";
import Tooltip from "../plugins/Tooltip";

export default function Carousel({ items }) {
    const carouselItems = items.map((item, key) => {
        return typeof item.href === "undefined" ? (
            <div key={key} className="itemWrapper">
                <img src={item.src} alt={item.alt} title={item.alt} className="item" />
            </div>
        ) : (
            <Tooltip key={key} tooltipText={item.alt}>
                <a className="itemWrapper" href={item.href} target="_blank" rel="noopener noreferrer">
                    <img src={item.src} alt={item.alt} className="item" />
                </a>
            </Tooltip>
        );
    });
    return <div>{carouselItems}</div>;
}
