import React from "react";
import Reference from "../../components/reference";

import image1 from "../../img/references/example/image1.png";
import image2 from "../../img/references/example/image2.png";
import image3 from "../../img/references/example/image3.png";
import image4 from "../../img/references/example/image4.png";
import image5 from "../../img/references/example/image5.png";
import image6 from "../../img/references/example/image6.png";
import image7 from "../../img/references/example/image7.png";
import image8 from "../../img/references/example/image8.png";
import iconAndroidText from "../../img/references/example/icon-android-text.png";
import iconIOSIPadOS from "../../img/references/example/icon-ios-ipados.png";
import iconFrameworks from "../../img/references/example/icon-frameworks.png";
import iconElectron from "../../img/references/example/icon-electron.png";
import iconAndroid from "../../img/references/example/icon-android.png";
import iconAngular from "../../img/references/example/icon-angular.png";
import iconCordova from "../../img/references/example/icon-cordova.png";
import iconGitlab from "../../img/references/example/icon-gitlab.png";
import iconIOS from "../../img/references/example/icon-ios.png";
import iconLaravel from "../../img/references/example/icon-laravel.png";
import iconLiferay from "../../img/references/example/icon-liferay.png";
import iconNodeJS from "../../img/references/example/icon-nodejs.png";
import iconReactNative from "../../img/references/example/icon-react-native.png";
import iconTrello from "../../img/references/example/icon-trello.png";
import iconWP from "../../img/references/example/icon-wordpress.png";

const Example = () => {
    return (
        <div>
            <Reference
                lang="en"
                areas={{
                    productDesign: true,
                    userExperience: true
                }}
                desc={
                    <p>We’re used to syncing with development sprint cycles and meeting client
                       deadlines. We’re used to syncing with development sprint cycles and meeting client
                       deadlines.</p>
                }
                comment={{
                    text: (
                        <p>We’re used to syncing with development sprint cycles and meeting client
                           deadlines. We’re used to syncing with development sprint cycles and meeting client
                           deadlines.</p>
                    ),
                    author: "Lukas"
                }}
                product={{
                    desc: (
                        <p>We’re used to syncing with development sprint cycles and meeting client
                           deadlines. We’re used to syncing with development sprint cycles and meeting client
                           deadlines.</p>
                    ),
                    image: { src: image5, alt: "" },
                    galleryImages: [
                        { src: image1, alt: "" },
                        { src: image2, alt: "" },
                        { src: image3, alt: "" },
                        { src: image4, alt: "" }
                    ],
                    sections: [
                        {
                            size: "half",
                            icon: { src: iconAndroidText, alt: "" },
                            title: "Native Android App",
                            desc: (
                                <p>We’re used to syncing with development sprint cycles and meeting client deadlines</p>
                            ),
                            actions: [
                                { to: "#", title: "Action button" }
                            ],
                            image: { src: image7, alt: "" }
                        },
                        {
                            size: "half",
                            title: "Native iOS App",
                            icon: { src: iconIOSIPadOS, alt: "" },
                            desc: (
                                <p>We’re used to syncing with development sprint cycles and meeting client deadlines</p>
                            ),
                            image: { src: image8, alt: "" }
                        },
                        {
                            size: "full",
                            icon: { src: iconFrameworks, alt: "" },
                            title: "Single Page Application",
                            actions: [
                                { to: "#", title: "Action button" }
                            ],
                            image: { src: image6, alt: "" }
                        }
                    ]
                }}
                technologies={{
                    desc: (
                        <p>We’re used to syncing with development sprint cycles and meeting client
                           deadlines. We’re used to syncing with development sprint cycles and meeting client
                           deadlines.</p>
                    ),
                    icons: [
                        { src: iconElectron, alt: "" },
                        { src: iconAndroid, alt: "" },
                        { src: iconAngular, alt: "" },
                        { src: iconCordova, alt: "" },
                        { src: iconGitlab, alt: "" },
                        { src: iconIOS, alt: "" },
                        { src: iconLaravel, alt: "" },
                        { src: iconLiferay, alt: "" },
                        { src: iconNodeJS, alt: "" },
                        { src: iconReactNative, alt: "" },
                        { src: iconTrello, alt: "" },
                        { src: iconWP, alt: "" }
                    ]
                }}
                actions={[
                    { to: "#", title: "Action" }
                ]} />
        </div>
    );
};

export default Example;
