import { useEffect, useState, useCallback } from "react";

export default function useIsInViewport() {
    const [refArr, setRefArr] = useState([]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        if (!("inviewport" in entry.target.dataset)) entry.target.setAttribute("data-inviewport", "true");
                    }
                });
            },
            { threshold: 0.1, rootMargin: "0px" }
        );

        refArr.length > 0 && refArr.forEach((item) => observer.observe(item));

        return () => observer.disconnect();
    }, [refArr]);

    const setRef = useCallback(
        (node) => {
            if (node !== null) {
                const isInArr = !!refArr.find((item) => item.dataset.observer === node.dataset.observer);
                if (!isInArr) setRefArr((oldRefArr) => [...oldRefArr, node]);
            }
        },
        [refArr]
    );

    return { setRef };
}
