const defaultPageProps = (currentPath) => {
    // console.log(currentPath);
    if (currentPath === "/") {
        return {
            docTitle: "Code 8",
            pageClassName: "homepage",
            showHeaderLogo: false,
            pageTitle: "Code Eight",
            pageIntroText: "We are team of web developers focusing on delivering high quality digital products. <br />We live and work in Prague.",
            subMenu: null,
        };
    } else if (currentPath === "/services/") {
        return {
            docTitle: "What we do - Code 8",
            pageClassName: "services",
            showHeaderLogo: true,
            pageTitle: "What we do",
            pageIntroText: "We don’t profess to do everything! But we have become specialists in a few valuable areas.",
            subMenu: "services",
        };
    } else if (currentPath === "/projects/") {
        return {
            docTitle: "Our work - Code 8",
            pageClassName: "projects",
            showHeaderLogo: true,
            pageTitle: "Our work",
            pageIntroText: "As a trusted solution provider, we work with clients spanning many industries on projects across different scopes.",
            subMenu: null,
        };
    } else if (currentPath === "/approach/") {
        return {
            docTitle: "Approach - Code 8",
            pageClassName: "approach",
            showHeaderLogo: true,
            pageTitle: "Approach",
            pageIntroText: "A remote team that’s anything but. For a project to be a success, we believe communication is key.",
            subMenu: "approach",
        };
    } else if (currentPath === "/about/") {
        return {
            docTitle: "About - Code 8",
            pageClassName: "about",
            showHeaderLogo: true,
            pageTitle: "About",
            pageIntroText: "Our team is based in Prague in the Czech Republic and consists of both back and front-end specialists.",
            subMenu: null,
        };
    } else if (currentPath === "/contact/") {
        return {
            docTitle: "Contact - Code 8",
            pageClassName: "contact",
            showHeaderLogo: true,
            pageTitle: "Contact",
            pageIntroText: "Let us know how we can help and we can arrange to have a chat on Skype or Google Hangouts.",
            subMenu: null,
        };
    } else if (currentPath === "/cz/") {
        // cesky
        return {
            docTitle: "Code 8",
            pageClassName: "homepage",
            showHeaderLogo: false,
            pageTitle: "Code Eight",
            pageIntroText: "Jsme programátoři a najdeme pro vás řešení. <br /> Moderní a efektivní.",
            subMenu: null,
        };
    } else if (currentPath === "/cz/co-umime/") {
        return {
            docTitle: "Co umíme - Code 8",
            pageClassName: "services",
            showHeaderLogo: true,
            pageTitle: "Co umíme",
            pageIntroText: "Ovládáme frontend i bekhend,<br />a to od základní čáry až po síť.",
            subMenu: "services",
        };
    } else if (currentPath === "/cz/projekty/") {
        return {
            docTitle: "Reference - Code 8",
            pageClassName: "projects",
            showHeaderLogo: true,
            pageTitle: "Reference",
            pageIntroText: "Jako spolehlivý poskytovatel IT řešení spolupracujeme s klienty z mnoha odvětví na&nbsp;projektech různého zaměření.",
            subMenu: null,
        };
    } else if (currentPath === "/cz/jak-pracujeme/") {
        return {
            docTitle: "Jak pracujeme - Code 8",
            pageClassName: "approach",
            showHeaderLogo: true,
            pageTitle: "Jak pracujeme",
            pageIntroText: "Na dálku, hned vedle vás, jeden dva dny v&nbsp;týdnu, ve&nbsp;dvou nebo ve&nbsp;třech lidech, zkrátka jak potřebujete.",
            subMenu: "approach",
        };
    } else if (currentPath === "/cz/o-nas/") {
        return {
            docTitle: "O nás - Code 8",
            pageClassName: "about",
            showHeaderLogo: true,
            pageTitle: "O nás",
            pageIntroText: "Začínali jsme ve dvou. <br />Dnes pro vás může pracovat až devět programátorů.",
            subMenu: null,
        };
    } else if (currentPath === "/cz/kontakt/") {
        return {
            docTitle: "Kontakt - Code 8",
            pageClassName: "contact",
            showHeaderLogo: true,
            pageTitle: "Kontakt",
            pageIntroText: "Ozvěte se a dejte vědět, s čím vám můžeme pomoci. Domluvíme&nbsp;si schůzku nebo naplánujeme hovor přes Skype.",
            subMenu: null,
        };
    } else if (currentPath === "/cz/prace/") {
        return {
            docTitle: "Nabídka práce - Code 8",
            pageClassName: "approach jobs",
            showHeaderLogo: true,
            pageTitle: "Nabídka práce",
            pageIntroText: "Sídlíme v Praze na Letné a&nbsp;momentálně hledáme programátora do party.",
            subMenu: null,
        };
    } else {
        console.warn("O oh! Defaulting to HP props. " + currentPath);
        return {
            docTitle: "Code 8",
            pageClassName: "homepage",
            showHeaderLogo: true,
            pageTitle: "Code Eight",
            pageIntroText: "We are team of web developers focusing on delivering high quality digital products. <br />We live and work in Prague.",
            subMenu: null,
        };
    }
};
export default defaultPageProps;
