import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'gatsby';
// import { HashLink } from 'react-router-hash-link';
import Navbar from './Navbar';
import { scrollWithOffset } from '../plugins/HelperFunctions';
import ThemeContext from '../../stores/ThemeContext';

const SubMenuServicesEn = () => {
    return (
        <ul className="submenu">
            <li><a href="#SSG" onClick={scrollWithOffset}>Next.JS</a></li>
            <li><a href="#jamstack" onClick={scrollWithOffset}>Jamstack apps</a></li>
            <li><a href="#mobileApps" onClick={scrollWithOffset}>Mobile Apps</a></li>
            <li><a href="#bespoke" onClick={scrollWithOffset}>Bespoke Development</a></li>
            <li><a href="#consulting" onClick={scrollWithOffset}>Consulting</a></li>
            <li><a href="#product" onClick={scrollWithOffset}>Product Design</a></li>
            {/* <li><a href="#experience" onClick={scrollWithOffset}>Platform Experience</a></li> */}
        </ul>
    );
};
const SubMenuApproachEn = () => {
    return (
        <ul className="submenu">
            <li><a href="#how" onClick={scrollWithOffset}>How we work</a></li>
            <li><a href="#extending" onClick={scrollWithOffset}>Extending your team</a></li>
            <li><a href="#planning" onClick={scrollWithOffset}>Project planning</a></li>
            <li><a href="#standards" onClick={scrollWithOffset}>Web Standards</a></li>
            <li><a href="#code" onClick={scrollWithOffset}>Your code</a></li>
        </ul>
    );
};
const SubMenuServicesCz = () => {
    return (
        <ul className="submenu">
            <li><a href="#SSG" onClick={scrollWithOffset}>Next.JS</a></li>
            <li><a href="#jamstack" onClick={scrollWithOffset}>Jamstack </a></li>
            <li><a href="#mobileApps" onClick={scrollWithOffset}>Mobilní aplikace</a></li>
            <li><a href="#bespoke" onClick={scrollWithOffset}>Řešení na míru</a></li>
            <li><a href="#konzulting" onClick={scrollWithOffset}>Poradenství</a></li>
            <li><a href="#product" onClick={scrollWithOffset}>Porudktový design</a></li>
            {/* <li><a href="#experience" onClick={scrollWithOffset}>A dál</a></li> */}
        </ul>
    );
};
const SubMenuApproachCz = () => {
    return (
        <ul className="submenu">
            <li><a href="#how" onClick={scrollWithOffset}>Malý velký tým</a></li>
            <li><a href="#extending" onClick={scrollWithOffset}>Půjčíme vám lidi</a></li>
            <li><a href="#planning" onClick={scrollWithOffset}>Martička</a></li>
            <li><a href="#code" onClick={scrollWithOffset}>*hit happens</a></li>
            <li><a href="#standards" onClick={scrollWithOffset}>Máme rádi Scrum</a></li>
        </ul>
    );
};

const StickyMenu = (props) => {
    const { themeOpt, dispatch } = useContext(ThemeContext);

    const [sticky, setSticky] = useState(false);
    const toggleMenu = () => {
        dispatch({ type: 'SET_MENU', payload: !themeOpt.menuOpen })
    };
    useEffect(() => {
        const handleScroll = (event) => {
            if (window.scrollY > themeOpt.heroHeight) {
                setSticky(true);
            } else {
                setSticky(false);
            }
        };
        // setState({ ...state, heroHeight: document.getElementById('hero').offsetHeight });
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [themeOpt.heroHeight])

    return (
        <section className="subMenu">
            <div id="stickyMenu" className={`${sticky ? "enabled" : ""}`}>
                <div id="in">
                    <Link to="/" className="logo-row">Code Eight</Link>
                    <button className="menuTrigger" onClick={toggleMenu}>Menu</button>

                    {themeOpt.lang === "en" && props.subMenu === "approach" && <SubMenuApproachEn />}
                    {themeOpt.lang === "en" && props.subMenu === "services" && <SubMenuServicesEn />}

                    {themeOpt.lang === "cz" && props.subMenu === "approach" && <SubMenuApproachCz />}
                    {themeOpt.lang === "cz" && props.subMenu === "services" && <SubMenuServicesCz />}

                    <Navbar className={`${themeOpt.menuOpen ? " open" : ""}`} />
                </div>
            </div>
        </section>
    );
};

export default StickyMenu;