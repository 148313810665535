const pagePropsReducer = (state, action) => {
    switch (action.type) {
        case "SET_PROPS":
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};
export default pagePropsReducer;
