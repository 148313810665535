export const scrollWithOffset = (el, offset) => {
  el.preventDefault();
  let scrollToId = el.target.getAttribute("href");
  if (scrollToId.indexOf("#") > -1) {
    scrollToId = scrollToId.substring(scrollToId.indexOf("#") + 1);
    //    console.log(scrollToId); 
    let scrollToElement = document.getElementById(scrollToId);
    //    console.log(scrollToElement);
    if (scrollToElement) {
      //  console.log(getPosition(el.attr("href")).y ) 
      const elementPosition = getOffsetTop(scrollToElement) + - 60;
      //        console.log(elementPosition)
      window.scroll({
        top: elementPosition,
        left: 0,
        behavior: "smooth"
      });
    }
  }
};

const getOffsetTop = element => {
  let offsetTop = 0;
  while (element) {
    offsetTop += element.offsetTop;
    element = element.offsetParent;
  }
  return offsetTop;
}
