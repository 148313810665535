const themeReducer = (state, action) => {
    switch (action.type) {
        case "UPDATE_BROWSER_DIMENSIONS":
            return {
                ...state,
                browserWidth: action.payload.browserWidth,
                browserHeight: action.payload.browserHeight,
                heroHeight: action.payload.heroHeight
            }
        case "SET_PAGE":
            return {
                ...state,
                pageId: action.payload.pageId,
                lang: action.payload.lang
            };
        case "SET_LANG":
            return {
                ...state,
                lang: action.payload.lang
            };
        case "SET_MENU":
            return {
                ...state,
                menuOpen: action.payload
            };
        default:
            return state;
    }
}
export default themeReducer;