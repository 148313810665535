module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://e607e52f535d4b918976be84968cb038@o463714.ingest.sentry.io/5469053","tracesSampleRate":1},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-31821556-1","head":true,"sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"code8.cz","anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Code 8 - We are team of web developers focusing on delivering high quality digital products.","short_name":"Code 8","description":"We are team of web developers focusing on delivering high quality digital products.","lang":"en","start_url":"/index.html","background_color":"#222222","theme_color":"#222222","display":"minimal-ui","icon":"src/img/logo-square-black.jpg","localize":[{"start_url":"/cz/index.html","lang":"cs","name":"Code 8","short_name":"Code 8","description":"Jsme programátoři a najdeme pro vás řešení. Moderní a efektivní."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4d1eb5e1e0a03036519ac0625d66596f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
