import React, { useContext } from "react";
import { Link } from "gatsby";
import Navbar from "./Navbar";
import ThemeContext from "../../stores/ThemeContext";

const MobileHeader = (props) => {
    const { themeOpt, dispatch } = useContext(ThemeContext);
    const toggleMenu = () => {
        dispatch({ type: "SET_MENU", payload: !themeOpt.menuOpen });
    };

    return (
        <div>
            <div id="mobile">
                <Link to="/" className="logo-row">
                    Code Eight
                </Link>
                <button className="menuTrigger" onClick={toggleMenu}>
                    Menu
                </button>
            </div>
            <div id="sidemenu" className={`${themeOpt.menuOpen ? "open" : ""}`}>
                <Navbar />
            </div>
        </div>
    );
};
export default MobileHeader;
