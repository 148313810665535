import React, { useEffect, useReducer } from "react";
import Header from "../components/layout/Header";
import MobileHeader from "../components/layout/MobileHeader";
import StickyMenu from "../components/layout/StickyMenu";
import Footer from "../components/layout/Footer";
import { Helmet } from "react-helmet";
import SubLayout from "../components/layout/SubLayout";
import PagePropsContext from "../stores/PagePropsContext";
import PagePropsReducer from "../stores/PagePropsReducer";
import ThemeContext from "../stores/ThemeContext";
import ThemeReducer from "../stores/ThemeReducer";
import defaultPageProps from "../defaultPageProps";
import CookieConsent from "../components/plugins/CookieConsent";
import initialState from "../stores/InitialState";
//import useBodyClass from '../hooks/setBodyClass';
import "../sass/main.scss";

const Layout = (props) => {
    const isClient = typeof window === "object";
    const initialThemeState = {
        ...initialState,
        browserWidth: isClient ? window.innerWidth : undefined,
        browserHeight: isClient ? window.innerHeight : undefined,
        heroHeight: isClient ? (document.getElementById("hero") ? document.getElementById("hero").offsetHeight : 0) : 0,
    };
    const [themeOpt, dispatch] = useReducer(ThemeReducer, initialThemeState);

    const initialPageProps = isClient ? defaultPageProps(props.path) : defaultPageProps(props.location.pathname);
    const [pageProps, setpageProps] = useReducer(PagePropsReducer, initialPageProps);

    const state2Props = () => {
        let newLang = props.path.indexOf("/cz/") > -1 ? "cz" : "en";
        dispatch({ type: "SET_LANG", payload: { lang: newLang } });
        dispatch({ type: "SET_MENU", payload: false });
        if (props.path === "/") {
            dispatch({ type: "SET_PAGE", payload: { pageId: 0, lang: newLang } });
        } else if (props.path === "/services/") {
            dispatch({ type: "SET_PAGE", payload: { pageId: 1, lang: newLang } });
        } else if (props.path === "/projects/") {
            dispatch({ type: "SET_PAGE", payload: { pageId: 2, lang: newLang } });
        } else if (props.path === "/approach/") {
            dispatch({ type: "SET_PAGE", payload: { pageId: 3, lang: newLang } });
        } else if (props.path === "/about/") {
            dispatch({ type: "SET_PAGE", payload: { pageId: 4, lang: newLang } });
        } else if (props.path === "/contact/") {
            dispatch({ type: "SET_PAGE", payload: { pageId: 5, lang: newLang } });
        } else if (props.path === "/cz/") {
            // cesky
            dispatch({ type: "SET_PAGE", payload: { pageId: 0, lang: newLang } });
        } else if (props.path === "/cz/co-umime/") {
            dispatch({ type: "SET_PAGE", payload: { pageId: 1, lang: newLang } });
        } else if (props.path === "/cz/projekty/") {
            dispatch({ type: "SET_PAGE", payload: { pageId: 2, lang: newLang } });
        } else if (props.path === "/cz/jak-pracujeme/") {
            dispatch({ type: "SET_PAGE", payload: { pageId: 3, lang: newLang } });
        } else if (props.path === "/cz/o-nas/") {
            dispatch({ type: "SET_PAGE", payload: { pageId: 4, lang: newLang } });
        } else if (props.path === "/cz/kontakt/") {
            dispatch({ type: "SET_PAGE", payload: { pageId: 5, lang: newLang } });
        } else if (props.path === "/cz/prace/") {
            dispatch({ type: "SET_PAGE", payload: { pageId: 6, lang: newLang } });
        }
        setpageProps({ type: "SET_PROPS", payload: defaultPageProps(props.path) });
    };

    useEffect(() => {
        // handle browser resize
        const handleResize = () => {
            dispatch({
                type: "UPDATE_BROWSER_DIMENSIONS",
                payload: {
                    browserWidth: isClient ? window.innerWidth : undefined,
                    browserHeight: isClient ? window.innerHeight : undefined,
                    heroHeight: isClient ? (document.getElementById("hero") ? document.getElementById("hero").offsetHeight : 0) : 0,
                },
            });
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [isClient]);

    useEffect(() => {
        state2Props();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.path]);

    return (
        <PagePropsContext.Provider value={{ pageProps, setpageProps }}>
            <ThemeContext.Provider value={{ themeOpt, dispatch }}>
                <div id="pageWrapper">
                    <Helmet>
                        <title>{pageProps.pageTitle}</title>
                        <body className={`${pageProps.pageClassName}`} />
                    </Helmet>
                    <CookieConsent />
                    <MobileHeader />
                    <Header />
                    <StickyMenu subMenu={pageProps.subMenu} />
                    <SubLayout>{props.children}</SubLayout>
                    <Footer />
                </div>
            </ThemeContext.Provider>
        </PagePropsContext.Provider>
    );
};
export default Layout;
